import { createRouter, createWebHistory } from 'vue-router';


const router = createRouter({

    routes: [
        {
            path: '/',
            //路由到的地址(自定义)
            redirect: '/indexdata',
            component: () => import('../components/indexs/indexs.vue'),
            hidden: true,
            children: [
                { path: "/indexdata", name: 'indexdata', component: () => import('../components/indexdata/indexdata.vue') },
                { path: "/cpmpanydes", name: 'cpmpanydes', component: () => import('../components/companyprofile/companyprofile.vue') },
                { path: "/productcenter", name: 'productcenter', component: () => import('../components/productcenter/productcenter.vue') },
                { path: "/productdetails", name: 'productdetails', component: () => import('../components/productdetails/productdetails.vue') },
                { path: "/newsinformation", name: 'newsinformation', component: () => import('../components/newsinformation/newsinformation.vue') },
                { path: "/contactus", name: 'contactus', component: () => import('../components/contactus/contactus.vue') },
            ],
        },

    ],
    history: createWebHistory(),
    //页面置顶
    // scrollBehavior: (to, from, savedPosition) => {
    //     if (savedPosition && to.meta.keepAlive) {
    //         return savedPosition;
    //     }
    //     return { left: 0, top: 0 };
    // }

})


export default router;
