<template>
  <el-config-provider :locale="locale">
    <div>
      <!-- <Indexs /> -->
      <!-- 路由 -->
      <router-view />
    </div>
  </el-config-provider>
</template>

<script>
 import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
  export default {
    components: {
      // Indexs
      [ElConfigProvider.name]: ElConfigProvider,
    },
    data() {
    return {
      locale: zhCn,
    }
  },
  }
  

</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
</style>